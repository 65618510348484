import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const FrontEndDevPage = () => (
  <Layout>
    <SEO
      title="Part-Time Front End Developer"
      description="Join the Pega Digital team as a front end developer. Click to learn more."
    />
    <div className="container">
      <main style={{ maxWidth: '700px', padding: '4rem 0 6rem' }}>
        <h1>Part-Time Front End Developer</h1>
        <p style={{ marginBottom: '4rem' }}>
          <strong>Listed by:</strong>{' '}
          <a
            style={{ textDecoration: 'underline' }}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/zachtrayner/"
          >
            Zach Trayner
          </a>
          , Founder
        </p>
        <h2>About Pega Digital</h2>
        <p>
          Pega Digital is a digital marketing startup that builds custom
          frontend web applications using the latest technologies such as React,
          GraphQL, Gatsby.js, TypeScript, and more. We also offer digital
          marketing services such as SEO/SEM, PPC, CRO, etc. Because we&apos;re
          just starting our team, it is important that candidates can wear
          multiple hats and take total ownership over their work.
        </p>
        <p>
          Prior to founding Pega Digital, I managed marketing websites for tech
          companies. I spent most of my time at Lucid Software (Lucidchart),
          before spending some time at a Silicon Valley startup.
        </p>
        <h2>About the Role</h2>
        <p>
          This is a frontend developer position. You will work remotely for the
          foreseeable future. You will learn how to build world-class websites
          that can scale to be used by billion dollar companies. You may also be
          asked to learn additional skills related to digital marketing, such as
          SEO, PPC, CRO, etc. If you already have design skills, you may be
          asked to produce mockups in Figma. We occasionally will also do
          quicker website builds using Webflow. It depends on the needs of each
          client. We are a quickly growing company, so we need to leverage the
          talents and skills of each individual who joins.
        </p>
        <p>
          If you want to specialize in frontend development, while learning how
          to build businesses online, then submit your application now.
        </p>
        <h2>What will you be working on?</h2>
        <p>
          You will be expected to work ~15-20 hours per week. The person hired
          for this position will build web applications usually using Gatsby.js,
          React, GraphQL, SCSS, etc. Occasionally perform work such as SEO
          (search engine optimization—keyword research, link building, etc.),
          PPC (manage purchasing of Google Ads), CRO (multivariate testing),
          and, if inclined, design work. We want to leverage your strengths for
          our clients and help you learn what you would like to learn.
        </p>
        <h2 style={{ display: 'block', margin: '2rem 0' }}>Requirements</h2>
        <h5 style={{ display: 'block' }}>Strongly Desired</h5>
        <ul>
          <li>
            ~1 year of programming experience from school or, preferably, work.
            Preferably using predominantly JavaScript and React.
          </li>
          <li>
            Proficient using CSS—you should know how to quickly create layouts
            using flexbox, positioning, and be aware of how to do cool things
            using psuedo elements, animations, etc.
          </li>
          <li>
            Strong communicator—you will be working remote. It is important for
            you to be proactive in your communication.
          </li>
          <li>Quick learner</li>
          <li>Entrepreneurial</li>
        </ul>
        <h5>Preferred</h5>
        <ul>
          <li>Have previous digital marketing or entrepreneurial experience</li>
          <li>
            Have excelled in any programming &amp; database classes you&apos;ve
            taken
          </li>
          <li>
            Previous experience using CMSes such as Drupal, WordPress,
            Contentful, Webflow, etc.
          </li>
        </ul>
        <h2>How to Apply</h2>
        <p>
          Please send an email to{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href="mailto:zach@pegadigital.com"
          >
            zach@pegadigital.com
          </a>{' '}
          with the following:
        </p>
        <ul>
          <li>Resume</li>
          <li>
            Cover letter (This just means write a paragraph about why you think
            we&apos;re a good fit)
          </li>
          <li>
            If current student: screenshot of grades &amp; course schedule for
            Fall semester
          </li>
          <li>How many hours per week you would prefer to work</li>
        </ul>
        <p>Expect a reply within 1-2 days!</p>
      </main>
    </div>
  </Layout>
);

export default FrontEndDevPage;
